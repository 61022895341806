<template>
  <div v-if="locale == 'pt'" id="noticias">
    <custom-lista
      v-if="!carregando"
      class="noticia-lista"
      :titulo="titulo"
      :itens="itensRef"
      width="300"
      height="auto"
    />
  </div>
</template>

<script setup>
const { t, locale } = useI18n();
const carregando = ref(true);
const { data: noticias } = await useFetch("/api/site/posts", {
  headers: { "Content-Language": locale },
});
const titulo = t("Blog da Sorrifácil");
let itensRef = ref([]);

onMounted(() => {
  Object.values(noticias.value).forEach((item) => {
    if (itensRef.value.length < 3) {
      itensRef.value.push({
        imagem: item.imagem_mobile,
        titulo: item.titulo,
        texto: item.descricao?.slice(0, 100) + "...",
        botao: { label: t("Ver mais"), url: `/blog/${item.slug}/` },
        class: "noticia-cartao",
      });
    }
  });
  carregando.value = false;
});
</script>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Noticias",
};
</script>
